import React from "react";

import { CardPair } from "./card";

const List = ({ data }) => {
  const leftCol = data.allMdx.edges.filter((edge, index) => index % 2 === 0);
  const rightCol = data.allMdx.edges.filter((edge, index) => index % 2 !== 0);
  return (
    <>
      {leftCol.map((prev, i) => {
        const next = rightCol[i];
        return (
          <CardPair key={prev.node.fields.route} prev={prev} next={next} />
        );
      })}
    </>
  );
};

export default List;
