import { graphql } from "gatsby";
import React from "react";

import { Header } from "../components/header";
import List from "../components/post/list";
import Footer from "../components/footer";

const Home = ({ data }) => (
  <div>
    <Header>Owen Kelly</Header>
    <List data={data} />
    <Footer />
  </div>
);

export default Home;
export const pageQuery = graphql`
  query IndexQuery {
    allMdx(limit: 2000, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...PostCardFragment
        }
      }
    }
  }
`;
