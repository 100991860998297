import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import { ThemeProviderProps } from "@src/theme";
import InternalLink from "@src/components/elements/link";
import { TagWithoutLink, SeriesWithoutLink } from "./tag";

const Link = styled(InternalLink)`
  text-decoration: none;
  flex: 1;
  border-radius: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin: 0.2rem;

  &:hover {
    background-color: ${(props: ThemeProviderProps) =>
      props.theme.color.CurrentLine};
    border-radius: 3px;
  }
  @media (max-width: 700px) {
    padding: 1rem 0;
    border-top: 1px solid
      ${(props: ThemeProviderProps) => props.theme.color.Selection};
  }
`;

const Title = styled.h1`
  color: ${(props: ThemeProviderProps) => props.theme.color.Foreground};
  font-weight: bold;
  font-size: 2rem;
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.HeaderSans};
  font-weight: 800;
  line-height: 1.2em;
  text-transform: uppercase;
  text-decoration: none;
  align-self: flex-start;
  width: 100%;
  flex-grow: 1;
  @media (max-width: 700px) {
    font-size: 1.2rem;
    margin-bottom: auto;
  }
`;

const Subtitle = styled.div`
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.HeaderSerif};
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  font-style: italic;
  font-weight: 100;
  text-transform: none;
  font-size: 1.4em;
  min-height: 2em;
  align-self: flex-end;
  justify-self: flex-end;
  width: 100%;
  @media (max-width: 700px) {
    font-size: 1rem;
    min-height: auto;
    line-height: 1.2em;
    margin: 0.4em auto;
  }
`;

const DateWrapper = styled.div`
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  color: ${(props: ThemeProviderProps) => props.theme.color.Purple};
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0.4rem 0;
  align-self: flex-start;
  width: 100%;
`;

const TagContainer = styled.div`
  margin-top: 1rem;
  @media (max-width: 700px) {
    margin-top: auto;
  }
`;

const Card = ({
  node: {
    fields: { route },
    frontmatter: { title, subtitle, date, tags },
  },
  ...props
}) => {
  const d = new Date(date);
  return (
    <Link to={route} {...props}>
      <DateWrapper>
        {d.toLocaleDateString("en-AU", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </DateWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <TagContainer>
        {tags && tags.map(t => <TagWithoutLink name={t} key={t} />)}
      </TagContainer>
    </Link>
  );
};

const CardPairWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${(props: ThemeProviderProps) => props.theme.dimensions.maxWidth};
  border-top: 1px solid
    ${(props: ThemeProviderProps) => props.theme.color.Selection};
  margin: 0rem auto 0 auto;
  padding: 4rem 0;
  width: 100%;
  @media (max-width: 700px) {
    display: block;
    border-top: none;
    padding: 0;
    margin: 0;
  }
`;

const CardPairInner = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${(props: ThemeProviderProps) => props.theme.dimensions.mainWidth};
  width: 100%;
  margin: 0 auto;
  @media (max-width: 700px) {
    display: block;
  }
`;

const Right = styled(Card)`
  text-align: right;
  @media (max-width: 700px) {
    text-align: left;
    width: 100%;
  }
`;
const Left = styled(Card)`
  text-align: left;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const CardPair = ({ prev, next, ...props }) => {
  if (!!prev && !!next) {
    return (
      <CardPairWrapper {...props}>
        <CardPairInner>
          {prev && <Right {...prev} />}
          {next && <Left {...next} />}
        </CardPairInner>
      </CardPairWrapper>
    );
  }
  if (next) {
    return (
      <CardPairWrapper {...props}>
        <CardPairInner>{next && <Left {...next} />}</CardPairInner>
      </CardPairWrapper>
    );
  }
  if (prev) {
    return (
      <CardPairWrapper {...props}>
        <CardPairInner>{prev && <Left {...prev} />}</CardPairInner>
      </CardPairWrapper>
    );
  }
};

export const query = graphql`
  fragment PostCardFragment on Mdx {
    fields {
      route
    }
    frontmatter {
      title
      subtitle
      date
      tags
    }
  }
`;

export default Card;
export { Card, CardPair };
