import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { ThemeProviderProps } from "../theme";
import { HeaderSans } from "../theme/fonts";

type TitleProps = { small?: boolean; children: any };
const Title = styled.h1<ThemeProviderProps & TitleProps>`
  color: ${props => props.theme.color.Foreground};
  font-weight: bold;
  font-size: ${({ small }) => (small ? "2rem" : "9rem")};
  font-family: ${HeaderSans};
  font-weight: 800;
  margin: ${({ small }) =>
    small ? "2rem auto 2rem auto" : "1rem auto 5rem auto"};
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin: 2rem auto 1rem auto;
  }
`;

const Header = ({ small = false, children }: TitleProps) => (
  <>
    <Helmet title={children} />
    <Title small={small}>{children}</Title>
  </>
);

export default Header;
export { Header };
